<template>
  <div class="container mt-2">
    <div>
      <h4>
        <router-link :to="{ name: 'home' }">
          <i class="fas fa-chevron-circle-left fa-lg" />
        </router-link>
        ตรวจผลรางวัล
      </h4>
    </div>
    <!-- Lotto THAI -->
    <div class="header-bg-content mb-2">
      <!-- พิเศษเฉพาะ-->
      <div class="container">
        <div>&nbsp;</div>
        <div class="lotto text-center">
          <div class="mb-1">
            <img
              src="th.svg"
              alt=""
              height="25"
            >
            <span class="YrVDG">
              ผลสลากกินแบ่งรัฐบาล งวดวันที่ 16/12/2564
            </span>
          </div>
          <b-row>
            <b-col
              lg="6"
              cols="12"
            >
              <h3>xxxxxx</h3>
              <small>รางวัลที่ 1</small>
            </b-col>

            <b-col
              lg="2"
              cols="4"
            >
              <h3>xxx xxx</h3>
              <small>เลขหน้า 3 ตัว</small>
            </b-col>

            <b-col
              lg="2"
              cols="4"
            >
              <h3>xxx xxx</h3>
              <small>เลขท้าย 3 ตัว</small>
            </b-col>

            <b-col
              lg="2"
              cols="4"
            >
              <h3>xx</h3>
              <small>เลขท้าย 2 ตัว</small>
            </b-col>
          </b-row>
        </div>

        <b-row class="mt-2 text-center">
          <b-col
            lg="6"
            cols="12"
            class="p-0"
          >
            <!-- Lotto THAI -->
            <div class="header-bg-content mb-2">
              <!-- พิเศษเฉพาะ-->
              <div class="container">
                <div class="lotto">
                  <div class="mb-1">
                    <img
                      src="https://www.เปิดสอบราชการ.com/img/articles/158634671920200416_154512.png"
                      alt=""
                      height="25"
                    >
                    <span class="YrVDG"> หวย ออมสิน 16/12/2564 </span>
                  </div>
                  <b-row>
                    <b-col
                      lg="6"
                      cols="6"
                    >
                      <h3>xxx</h3>
                      <small>3ตัวบน</small>
                    </b-col>

                    <b-col
                      lg="6"
                      cols="6"
                    >
                      <h3>xx</h3>
                      <small>2ตัวล่าง</small>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- พิเศษเฉพาะ -->
            </div>
            <!-- End Lotto THAI -->
          </b-col>

          <b-col
            lg="6"
            cols="12"
            class="p-0"
          >
            <!-- Lotto THAI -->
            <div class="header-bg-content mb-2">
              <!-- พิเศษเฉพาะ-->
              <div class="container">
                <div class="lotto">
                  <div class="mb-1">
                    <img
                      src="https://www.tode365.com/wp-content/uploads/2021/08/%E0%B8%98%E0%B8%81%E0%B8%AA.png"
                      alt=""
                      height="25"
                    >
                    <span class="YrVDG"> หวย ธกส. 16/12/2564 </span>
                  </div>
                  <b-row>
                    <b-col
                      lg="6"
                      cols="6"
                    >
                      <h3>xxx</h3>
                      <small>3ตัวบน</small>
                    </b-col>

                    <b-col
                      lg="6"
                      cols="6"
                    >
                      <h3>xx</h3>
                      <small>2ตัวล่าง</small>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- พิเศษเฉพาะ -->
            </div>
            <!-- End Lotto THAI -->
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col
            lg="12"
            cols="12"
            class="p-0"
          >
            <!-- Lotto THAI -->
            <div class="header-bg-content mb-2">
              <!-- พิเศษเฉพาะ-->
              <div class="container">
                <div class="lotto">
                  <div class="mb-1">
                    <img
                      src="/th.svg"
                      alt=""
                      height="25"
                    >
                    <span class="YrVDG"> หวยหุ้นไทย 2021-12-25 </span>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <b-table
                        striped
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                        <template #cell(avatar)="data">
                          <b-avatar :src="data.value" />
                        </template>

                        <template #cell(status)="data">
                          <b-badge :variant="status[1][data.value]">
                            {{ status[0][data.value] }}
                          </b-badge>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- พิเศษเฉพาะ -->
            </div>
            <!-- End Lotto THAI -->
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col
            lg="12"
            cols="12"
            class="p-0"
          >
            <!-- Lotto THAI -->
            <div class="header-bg-content mb-2">
              <!-- พิเศษเฉพาะ-->
              <div class="container">
                <div class="lotto">
                  <div class="mb-1">
                    <img
                      src="/th.svg"
                      alt=""
                      height="25"
                    >
                    <span class="YrVDG"> หวยหุ้นต่างประเทศ 2021-12-25 </span>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <b-table
                        striped
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                        <template #cell(avatar)="data">
                          <b-avatar :src="data.value" />
                        </template>

                        <template #cell(status)="data">
                          <b-badge :variant="status[1][data.value]">
                            {{ status[0][data.value] }}
                          </b-badge>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- พิเศษเฉพาะ -->
            </div>
            <!-- End Lotto THAI -->
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col
            lg="12"
            cols="12"
            class="p-0"
          >
            <!-- Lotto THAI -->
            <div class="header-bg-content mb-2">
              <!-- พิเศษเฉพาะ-->
              <div class="container">
                <div class="lotto">
                  <div class="mb-1">
                    <img
                      src="/th.svg"
                      alt=""
                      height="25"
                    >
                    <span class="YrVDG"> หวยหุ้นไทย 2021-12-25 </span>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <b-table
                        striped
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                        <template #cell(avatar)="data">
                          <b-avatar :src="data.value" />
                        </template>

                        <template #cell(status)="data">
                          <b-badge :variant="status[1][data.value]">
                            {{ status[0][data.value] }}
                          </b-badge>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- พิเศษเฉพาะ -->
            </div>
            <!-- End Lotto THAI -->
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col
            lg="12"
            cols="12"
            class="p-0"
          >
            <!-- Lotto THAI -->
            <div class="header-bg-content mb-2">
              <!-- พิเศษเฉพาะ-->
              <div class="container">
                <div class="lotto">
                  <div class="mb-1">
                    <img
                      src="/th.svg"
                      alt=""
                      height="25"
                    >
                    <span class="YrVDG"> ซุปเปอร์ยี่กี 2021-12-25 </span>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <b-table
                        striped
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                        <template #cell(avatar)="data">
                          <b-avatar :src="data.value" />
                        </template>

                        <template #cell(status)="data">
                          <b-badge :variant="status[1][data.value]">
                            {{ status[0][data.value] }}
                          </b-badge>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- พิเศษเฉพาะ -->
            </div>
            <!-- End Lotto THAI -->
          </b-col>
        </b-row>
      </div>
      <!-- พิเศษเฉพาะ -->
    </div>
    <!-- End Lotto THAI -->
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BTable, BAvatar, BBadge,
} from 'bootstrap-vue'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import HomeBanner from './HomeBanner.vue'
// import HomeSponsor from './HomeSponsor.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    BButton,
    // HomeBanner,
    // HomeSponsor,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/salon/1.jpg') },
        { img: require('@/assets/images/salon/2.jpg') },
        { img: require('@/assets/images/salon/3.jpg') },
        { img: require('@/assets/images/salon/4.jpg') },
        { img: require('@/assets/images/salon/5.jpg') },
        { img: require('@/assets/images/salon/6.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
        pagination: {
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        },
      },
      fields: [
        { key: 'round', label: 'รอบ' },
        { key: '3', label: '3ตัวบน' },
        { key: '2', label: '2ตัวล่าง' },
      ],
      items: [
        {
          round: 'หุ้นไทยเช้า',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเที่ยง',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยบ่าย',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเย็น',
          3: 'xxx',
          2: 'xx',
        },
      ],
    }
  },
  methods: {},
}
</script>

<style scoped>
.balance {
  border: 1px solid rgb(255, 123, 123);
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  background-image: url(https://fn.dmpcdn.com/Components/HeaderMenu/images/red-defual-header.svg);
  background-position: left bottom;
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: unset;
}
body,
html {
  padding: 0;
  margin: 0;
}
.img-mark {
  border-radius: 7px;
}
h3 {
  font-size: 1.8rem;
  font-weight: bold;
}
.header-bg {
  background-color: rgb(255, 255, 255);
  padding: 4px;
}
.header-bg-content {
  background-color: #ffffff;
}
.YrVDG {
  font-size: 1.5rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(36, 36, 36);
}
@media (max-width: 574px) {
  .YrVDG {
    font-size: 1.2rem;
    font-weight: 700;
  }
}
.text-card {
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.container-spon {
  margin: auto;
  max-width: 640px;
}

.bg-balance {
  padding: 10px 25px 10px 25px;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgb(255, 110, 110);
}

.bg-balance2 {
  border: 1px solid rgb(255, 0, 0);
  border-radius: 10px;
  background-image: url(/images/bg-header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  border-radius: 9px;
}

.btn-menu {
  border: 1px solid rgb(255, 123, 123);
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  border-radius: 10px;
  background-color: #b62525;
}

.lotto {
  /* text-align: center; */
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 0.3rem;
}
</style>
